import * as React from "react";
import PropTypes from "prop-types";

import { RoundedArrow, VerticalYellowLineShape, HorizontalYellowLineShape2, HorizontalYellowLineShape3 } from '../../Atoms/Icons/Icons'

import './TitleHistory.scss'

 var classNames = require('classnames');
 
 function TitleHistory({ label, title }) {
  
   return (
    <div className="title_history">
      <div className="icon">
        <HorizontalYellowLineShape3 />
      </div>
      <div className="title_text">
        <label>{label}</label>
        <h2>{title}</h2>
      </div>
    </div>
   );
 }
 
 TitleHistory.defaultProps = {
   title: `Title`,
   label: `arrow`
 };
 
 TitleHistory.propTypes = {
   title: PropTypes.string,
   label: PropTypes.string
 };


export default TitleHistory;