import React from "react";
import Layout from "../../../../Layout";
import Seo from "../../../../components/Seo/Seo";
import Breadcrumb from "../../../../components/Breadcrumb/Breadcrumb";
import ScrollPageNav from "../../../../components/ScrollPageNav/ScrollPageNav";
import TitleHistory from "../../../../Molecules/TitleHistory/TitleHistory";
import TitlePage from "../../../../Molecules/TitlePage/TitlePage";
import VideoMP4 from "../../../../components/Video/VideoMP4";
import { Button } from "../../../../Molecules/Button/Button";

import BImage from "../../../../assets/images/varlorcol_desktop.png";

import "./styles.scss";
import { RoundedArrow } from "../../../../Atoms/Icons/Icons";

const ColasHistory = () => {
  var classNames = require("classnames");

  const stringToHTML = (text) => {
    return { __html: text };
  };

  const history_section = [
    {
      title: "2020",
      section: "year_twenty_twenty",
      img_mobile:
        require("../../../../assets/images/history/mobile/NANTES-ExperimentationFlowell-2020.png")
          .default,
      img_desktop: [
        {
          img: require("../../../../assets/images/history/desktop/NANTES-ExperimentationFlowell-2020.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
      ],
      texts: [
        {
          text: "Lorem ipsum dolor sit<strong> consetetur sadipscing </strong>elitr.",
        },
        {
          text: "Sed diam nonumy eirmod tempor invidunt ut labore et dolore<strong> magna aliquyam </strong>erat, sed diam voluptua. At.",
        },
        {
          text: "Lorem ipsum<strong> dolor sit amet</strong>, consetetur sadipscing elitr.",
        },
      ],
      link: "/",
    },
    {
      title: "2010",
      section: "year_twenty_ten",
      img_mobile:
        require("../../../../assets/images/history/mobile/NANTES-ExperimentationFlowell-2020.png")
          .default,
      img_desktop: [
        {
          img: require("../../../../assets/images/history/desktop/NANTES-ExperimentationFlowell-2020.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
      ],
      texts: [
        {
          text: "Lancement de la route photovoltaïque <strong>Wattway</strong>",
        },
        { text: "Création de Colas <strong>Projects</strong>" },
        { text: "Acquisition de <strong>Miller McAsphalt</strong>" },
      ],
      link: "/",
    },
    {
      title: "2000",
      section: "year_twenty",
      img_mobile:
        require("../../../../assets/images/history/mobile/NANTES-ExperimentationFlowell-2020.png")
          .default,
      img_desktop: [
        {
          img: require("../../../../assets/images/history/desktop/NANTES-ExperimentationFlowell-2020.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
      ],
      texts: [
        { text: "Création du<strong> pôle ferroviaire </strong>Colas Rail" },
        { text: "Colas s’implante en <strong>Australie</strong>" },
        {
          text: "Colas crée <strong>Végécol®</strong>, le premier liant routier d’origine végétale",
        },
      ],
      link: "/",
    },
    {
      title: "1990",
      section: "year_ninety_nineteen",
      img_mobile:
        require("../../../../assets/images/history/mobile/NANTES-ExperimentationFlowell-2020.png")
          .default,
      img_desktop: [
        {
          img: require("../../../../assets/images/history/desktop/NANTES-ExperimentationFlowell-2020.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
      ],
      texts: [
        { text: "Expansion en <strong>Europe</strong>" },
        {
          text: "Nouvelles activités :</br><strong>réseaux</strong> et <strong>étanchéité</strong>",
        },
        { text: "Création de la <strong>Fondation Colas</strong>" },
      ],
      link: "/",
    },
    {
      title: "1980",
      section: "year_eighty_eighteen",
      img_mobile:
        require("../../../../assets/images/history/mobile/NANTES-ExperimentationFlowell-2020.png")
          .default,
      img_desktop: [
        {
          img: require("../../../../assets/images/history/desktop/NANTES-ExperimentationFlowell-2020.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
      ],
      texts: [
        { text: "La SRC devient <strong>Colas</strong>" },
        {
          text: "<strong>Bouygues</strong> devient l’actionnaire majoritaire de Colas",
        },
        { text: "Premier contrat en <strong>Asie</strong>" },
        { text: "Colas dans l’Océan <strong>Indien<strong>" },
      ],
      link: "/",
    },
    {
      title: "1970",
      section: "year_seventy_seventeen",
      img_mobile:
        require("../../../../assets/images/history/mobile/NANTES-ExperimentationFlowell-2020.png")
          .default,
      img_desktop: [
        {
          img: require("../../../../assets/images/history/desktop/NANTES-ExperimentationFlowell-2020.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
      ],
      texts: [
        { text: "La SRC participe au groupement <strong>Cofiroute</strong>" },
        { text: "Mise au point de <strong>Colflex®</strong>" },
        {
          text: "Création de la <strong>première filiale américaine du Groupe</strong> Barrett Paving Materials",
        },
      ],
      link: "/",
    },
    {
      title: "1960",
      section: "year_sixty_sixteen",
      img_mobile:
        require("../../../../assets/images/history/mobile/NANTES-ExperimentationFlowell-2020.png")
          .default,
      img_desktop: [
        {
          img: require("../../../../assets/images/history/desktop/NANTES-ExperimentationFlowell-2020.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
      ],
      texts: [
        { text: "Première <strong>cotation boursière</strong>" },
        { text: "Premières activités en <strong>Amérique du Nord</strong>" },
        { text: "Nouvelle activité :</br><strong>carrières</strong>" },
      ],
      link: "/",
    },
    {
      title: "1950",
      section: "year_fivety_fiveteen",
      img_mobile:
        require("../../../../assets/images/history/mobile/NANTES-ExperimentationFlowell-2020.png")
          .default,
      img_desktop: [
        {
          img: require("../../../../assets/images/history/desktop/NANTES-ExperimentationFlowell-2020.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
      ],
      texts: [
        {
          text: "Création du<strong> Département Technique </strong>et du Laboratoire Central de Bonneuil-sur-Marne",
        },
        { text: "Nouvelles activités :</br><strong>signalisation</strong>" },
        {
          text: "Le groupe<strong> Shell cède le contrôle </strong>de la Société Routière Colas",
        },
      ],
      link: "/",
    },
    {
      title: "1940",
      section: "year_fourty_fourteen",
      img_mobile:
        require("../../../../assets/images/history/mobile/NANTES-ExperimentationFlowell-2020.png")
          .default,
      img_desktop: [
        {
          img: require("../../../../assets/images/history/desktop/NANTES-ExperimentationFlowell-2020.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
      ],
      texts: [
        {
          text: "La SRC fait l’objet d’une mesure de <strong>séquestre</strong>",
        },
        { text: "La SRC,<strong> numéro 1 </strong>des travaux routiers" },
        {
          text: "Création de trois<strong> filiales </strong>de Colas hors métropole",
        },
      ],
      link: "/",
    },
    {
      title: "1930",
      section: "year_thirty_thirteen",
      img_mobile:
        require("../../../../assets/images/history/mobile/NANTES-ExperimentationFlowell-2020.png")
          .default,
      img_desktop: [
        {
          img: require("../../../../assets/images/history/desktop/NANTES-ExperimentationFlowell-2020.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
        {
          img: require("../../../../assets/images/history/desktop/bmw-press-release-photo2.png")
            .default,
        },
      ],
      texts: [
        {
          text: "Détention de la totalité du capital par la <strong>Royal Dutch Shell</strong>",
        },
        {
          text: "La Société Routière Colas (SRC) réalise ses <strong>premiers chantiers routiers</strong> en France",
        },
        {
          text: "La SRC se développe en <strong>Afrique</strong> et aux <strong>Antilles</strong>",
        },
      ],
      link: "/",
    },
  ];

  return (
    <Layout>
      <Seo
        title="Colas History"
        description="Description de la page Colas History"
      />
      <div className={classNames("page_template", "colas_history_page")}>
        <ScrollPageNav
          data={[
            {
              title: "2020",
              section: "year_twenty_twenty",
            },
            {
              title: "2010",
              section: "year_twenty_ten",
            },
            {
              title: "2000",
              section: "year_twenty",
            },
            {
              title: "1990",
              section: "year_ninety_nineteen",
            },
            {
              title: "1980",
              section: "year_eighty_eighteen",
            },
            {
              title: "1970",
              section: "year_seventy_seventeen",
            },
            {
              title: "1960",
              section: "year_sixty_sixteen",
            },
            {
              title: "1950",
              section: "year_fivety_fiveteen",
            },
            {
              title: "1940",
              section: "year_fourty_fourteen",
            },
            {
              title: "1930",
              section: "year_thirty_thirteen",
            },
          ]}
        />
        <div className="wrapper_page">
          <Breadcrumb
            page_banner={false}
            data={{
              grandParentPage: { title: "Accueil", url: "/" },
              parentPage: { title: "Groupe", url: "/groupe" },
              currentPage: {
                title: "Histoire de Colas",
                url: "/about-us/colas-history",
              },
            }}
          />
          <TitlePage color="color_dark_bleu" title="Histoire de Colas" />
          <VideoMP4 cover={BImage} />
        </div>
        <div className="wrapper_page">
          {history_section.map((history, i) => (
            <section
              key={i}
              className={classNames("section_content", history.section)}
            >
              <div className="only_mobile_tablet">
                <TitleHistory
                  label="Décennie"
                  title={history.title}
                  type="line1"
                />
                <div className="image_year">
                  <img src={history.img_mobile} alt="" />
                </div>
                <div className="text_section">
                  {history.texts.map((texts, i) => (
                    <div className="title_container" key={i}>
                      <div className="icon">
                        <RoundedArrow />
                      </div>
                      <div className="title_text" key={i}>
                        <p dangerouslySetInnerHTML={stringToHTML(texts.text)} />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="button_discover">
                  <Button
                    primary={true}
                    label="Découvrir"
                    link={history.link}
                  />
                </div>
              </div>
              <div className="only_desktop">
                <div className="middle_desktop">
                  <div className="text_container">
                    <div className="container_title">
                      <TitleHistory
                        label="Décennie"
                        title={history.title}
                        type="line1"
                      />
                    </div>
                    <div className="text_section">
                      {history.texts.map((texts, i) => (
                        <div className="title_container" key={i}>
                          <div className="icon">
                            <RoundedArrow />
                          </div>
                          <div className="title_text" key={i}>
                            <p
                              dangerouslySetInnerHTML={stringToHTML(texts.text)}
                            />
                          </div>
                        </div>
                      ))}
                      <div className="button_discover">
                        <Button
                          primary={true}
                          label="Découvrir"
                          link={history.link}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="image_year">
                    {history.img_desktop.map((image, i) => (
                      <img src={image.img} alt="" key={i} />
                    ))}
                  </div>
                </div>
              </div>
            </section>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default ColasHistory;
